import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/SiteLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "drawing"
    }}>{`Drawing`}</h1>
    <p>{`In this book we're going to draw computer graphics to learn about programming. `}<a parentName="p" {...{
        "href": "/what-is-javascript#-helpers"
      }}>{`In the previous chapter`}</a>{` we saw some helpers that aren't naturally part of JavaScript per se, but we (the authors) added in by us so we can do some cool drawing stuff while learning about programming concepts. In this chapter, we'll explore some of the drawing functionalities we built into this site.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Don't expect to understand the code in this chapter! Just pay attention to what types of drawing things we can do.`}</p>
    </blockquote>
    <h2 {...{
      "id": "the-stage"
    }}>{`The "Stage"`}</h2>
    <p>{`Recall that our "sandboxes" consist of some key pieces, including the "stage", which is where our drawings will show up.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5cad27601d76a0444793789f7b0f1f55/b6e34/sandbox-breakdown.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.4%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Sandbox breakdown",
            "title": "Sandbox breakdown",
            "src": "/static/5cad27601d76a0444793789f7b0f1f55/00d43/sandbox-breakdown.png",
            "srcSet": ["/static/5cad27601d76a0444793789f7b0f1f55/63868/sandbox-breakdown.png 250w", "/static/5cad27601d76a0444793789f7b0f1f55/0b533/sandbox-breakdown.png 500w", "/static/5cad27601d76a0444793789f7b0f1f55/00d43/sandbox-breakdown.png 1000w", "/static/5cad27601d76a0444793789f7b0f1f55/b6e34/sandbox-breakdown.png 1103w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can resize the stage by dragging the edges of the stage. The size of the stage (in pixels) will show up at the bottom-right corner of the stage.`}</p>
    <p>{`Now, let's look at some helper methods for drawing things onto the stage.`}</p>
    <h2 {...{
      "id": "the-log-helper"
    }}>{`The $log helper`}</h2>
    <p>{`We can "log" values to the screen using the `}<inlineCode parentName="p">{`$log`}</inlineCode>{` helper. This helper doesn't draw anything to the stage, but instead prints some text into a "logging" area. Here's an example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=drawing-log",
        "live": "true",
        "id": "drawing-log"
      }}>{`$log("Hello world!");
$log(15 * 35);
`}</code></pre>
    <p>{`Logging values well help us investigate some ideas and test things. This will sometimes be helpful for us as we explore ideas before we use those ideas in drawings.`}</p>
    <h2 {...{
      "id": "the-text-helper"
    }}>{`The $text helper`}</h2>
    <p>{`We can display text on our drawing stage using the `}<inlineCode parentName="p">{`$text`}</inlineCode>{` helper. This command will take a piece of text, an x-position, a y-position, and an optional configuration, and will draw the text. Here's an example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=drawing-$text",
        "live": "true",
        "id": "drawing-$text"
      }}>{`const theText = "Hello world!";
const x = 50;
const y = 100;

// Our $text helper
$text(theText, x, y, { fontSize: 24, fill: "blue" });
`}</code></pre>
    <h2 {...{
      "id": "the-circle-helper"
    }}>{`The $circle helper`}</h2>
    <p>{`We can display circles using the `}<inlineCode parentName="p">{`$circle`}</inlineCode>{` helper. This command will take a radius, an x and y position for the center of the circle, and a configuration, and will draw the circle accordingly. Here's an example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=drawing-$circle",
        "live": "true",
        "id": "drawing-$circle"
      }}>{`const radius = 50;
const x = $stageWidth / 2;
const y = $stageHeight / 2;

// Our $circle helper
$circle(radius, x, y, { fill: "green" });
`}</code></pre>
    <h2 {...{
      "id": "the-rect-helper"
    }}>{`The $rect helper`}</h2>
    <p>{`We can display rectangles using the `}<inlineCode parentName="p">{`$rect`}</inlineCode>{` helper. This command will take a width, a height, an x and y position for the top-left corner of the rectangle, and a configuration - and will draw the rectangle accordingly. Here's an example.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=drawing-$rect",
        "live": "true",
        "id": "drawing-$rect"
      }}>{`const width = 100;
const height = 50;
const x = 10;
const y = 20;

// Our $rect helper
$rect(width, height, x, y, { fill: "purple" });
`}</code></pre>
    <h2 {...{
      "id": "stagewidth-and-stageheight"
    }}>{`$stageWidth and $stageHeight`}</h2>
    <p>{`You can resize the stage, so the size of the stage can actually change. We've added some helpers so you can always have access to the current size of the stage. The variable (`}<a parentName="p" {...{
        "href": "/variables"
      }}>{`more on those later`}</a>{`) `}<inlineCode parentName="p">{`$stageWidth`}</inlineCode>{` will give you the current width of the stage, and `}<inlineCode parentName="p">{`$stageHeight`}</inlineCode>{` will give you the current height of the stage.`}</p>
    <p>{`These helpers will be super useful for us, so we can draw things relative to the size of the stage! Here's an example that will put circles at each 4 corners of the stage. Try resizing the stage in the sandbox below and then rerun the code, and notice that the circles will be drawn at the corners.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=drawing-$stageWidth",
        "live": "true",
        "id": "drawing-$stageWidth"
      }}>{`// Top left
$circle(50);

// Top right
$circle(50, $stageWidth, 0);

// Bottom right
$circle(50, $stageWidth, $stageHeight);

// Bottom left
$circle(50, 0, $stageHeight);
`}</code></pre>
    <h2 {...{
      "id": "the-animate-helper"
    }}>{`The $animate helper`}</h2>
    <p>{`We've also built in a helper that will animate (or change/move) items on the stage. This allows us to extend our drawings into `}<em parentName="p">{`animations`}</em>{`, which will expand our drawing capabilities significantly! Here's a quick example (don't worry if the code looks confusing).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=drawing-$animate",
        "live": "true",
        "id": "drawing-$animate"
      }}>{`const r = $rect(40, 40, 20, 20, { offsetX: 20, offsetY: 20 });

$animate({ item: r, x: $stageWidth/2, y: $stageHeight/2, duration: 1, rotation: 180, scaleX: $stageWidth/40, fill: "green" });
$animate({ item: r, scaleY: $stageHeight/40, fill: "blue", duration: 1 })
$animate({ item: r, scaleX: 0, scaleY: 0 });
$animate({ item: r, scaleX: 2, scaleY: 2, duration: 1, fill: "red", cornerRadius: 20 });
`}</code></pre>
    <p>{`We can animate effectively `}<em parentName="p">{`any`}</em>{` property of our drawing objects. We'll go into depth with this helper later on. For you, just press the Run button in the sandbox above and watch the shape change!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      